"use client";
import CloseIcon from "@/app/assets/images/svg/xmark.svg";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import styles from "./modal.module.scss";

interface Props {
	isOpen?: boolean;
	ariaLabel?: string;
	contentId?: string;
	contentClassName?: string;
	trigger?: React.ReactNode;
	title?: React.ReactNode;
	modalBackground?: "dark" | "light";
	toggleModal: () => void;
	children: React.ReactNode;
}

export default function Modal({
	isOpen,
	title,
	trigger,
	contentId,
	ariaLabel,
	contentClassName,
	modalBackground,
	toggleModal,
	children,
}: Props) {
	const pathname = usePathname();

	const [previousPathname] = useState(pathname);

	useEffect(() => {
		if (isOpen && pathname !== previousPathname) {
			toggleModal();
		}
	}, [pathname]);

	return (
		<Dialog.Root open={isOpen}>
			{!!trigger && (
				<Dialog.Trigger onClick={toggleModal} asChild>
					{trigger}
				</Dialog.Trigger>
			)}
			<Dialog.Portal>
				<Dialog.Overlay className={styles.overlay} onClick={toggleModal} />
				<Dialog.Content
					className={clsx(styles.content, contentClassName)}
					aria-label={ariaLabel}
					id={contentId}
					aria-labelledby={undefined}
					aria-describedby={undefined}
				>
					{!!title && (
						<Dialog.Title className={styles.title}>{title}</Dialog.Title>
					)}
					<Dialog.Close
						className={clsx({
							[styles.closeLight]: modalBackground === "dark",
							[styles.close]: modalBackground !== "dark",
						})}
						asChild
						onClick={toggleModal}
					>
						<CloseIcon />
					</Dialog.Close>
					{children}
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
}
