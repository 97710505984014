import React, { FC } from "react";
import styles from "./FooterSocialLinks.module.scss";

interface FooterSocialLinksProps {
	isECom: boolean;
	socials: {
		link: string;
		ariaLabel: string;
		image: string;
		altText: string;
	}[];
}

const FooterSocialLinks: FC<FooterSocialLinksProps> = ({ socials, isECom }) => {
	return (
		<div className={styles.FooterSocials}>
			<div className={styles.FooterInnerBottomSocial}>
				{isECom && <button type='button'>Change Region & Language</button>}
				<nav>
					<ul>
						{socials?.map((social) => {
							return (
								<li key={social.link}>
									<a
										href={social.link}
										aria-label={social.ariaLabel}
										target='_blank'
										rel='noreferrer'
									>
										<img src={social.image} alt={social.altText} />
									</a>
								</li>
							);
						})}
					</ul>
				</nav>
			</div>
		</div>
	);
};

export default FooterSocialLinks;
